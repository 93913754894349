import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";
import Copyright from "../internals/components/Copyright";

export default function LocationGrid() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // Panggil useDropzone di atas, sebelum kondisi apapun
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    accept: {
      "text/html": [".csv"],
    },
  });

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch locations");
        }

        const result = await response.json();
        setLocations(result.data || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, [token, navigate]);

  const handleUpload = async () => {
    if (!file) {
      console.log("No file selected for upload");
      return;
    }

    console.log("Preparing to upload file:", file);

    // Tampilkan SweetAlert loading sebelum memulai upload
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while the file is being uploaded.",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    console.log("FormData prepared with file:", file.name);

    try {
      const response = await fetch(`${API_URL}/v1/admin/locations/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      console.log("Upload response status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.log("Error response:", errorText);
        throw new Error("Failed to upload file: " + errorText);
      }

      const result = await response.json();
      console.log("Upload successful, response data:", result);

      setLocations(result.data || []); // Update data lokasi setelah upload berhasil
      setFile(null); // Reset file setelah berhasil upload
      setOpen(false); // Tutup modal setelah upload selesai

      // Tampilkan SweetAlert sukses setelah upload selesai
      Swal.fire({
        icon: "success",
        title: "Upload Successful",
        text: "The file has been uploaded successfully!",
      });
    } catch (error) {
      console.error("Upload failed:", error.message); // Log error ke konsol

      // Tampilkan SweetAlert error jika upload gagal
      Swal.fire({
        icon: "error",
        title: "Upload Failed",
        text: error.message,
      });

      setError(error.message); // Tampilkan pesan error jika gagal
    } finally {
      setUploading(false);
      console.log("Upload process completed");
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const columns = [
    {
      accessorKey: "code", // Accessor key for the "code" field
      header: "Code",
    },
    {
      accessorKey: "name", // Accessor key for the "name" field
      header: "Name",
    },
    {
      accessorKey: "created_at", // Accessor key for the "created_at" field
      header: "Created At",
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
    },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" }, p: 2 }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        LOCATION DATA
      </Typography>

      <Button variant="contained" sx={{ mb: 2 }} onClick={() => setOpen(true)}>
        Upload Location Data
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Upload Location CSV</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: "2px dashed #ccc",
              borderRadius: "8px",
              p: 2,
              textAlign: "center",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {file ? (
              <Typography>{file.name}</Typography>
            ) : (
              <Typography>
                Drag and drop a file here, or click to select
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleUpload}
            variant="contained"
            disabled={!file || uploading}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialReactTable
        columns={columns}
        data={locations}
        enableSorting
        enablePagination
        enableBottomToolbar
      />

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
