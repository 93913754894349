import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { validateToken } from "./authUtils";

const RestrictedRoute = () => {
  const [authStatus, setAuthStatus] = useState({ isValid: null, role: null });

  useEffect(() => {
    const checkToken = async () => {
      const result = await validateToken();
      setAuthStatus(result);
    };

    checkToken();
  }, []);

  if (authStatus.isValid === null) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-blue-500"></div>
      </div>
    );
  }

  // Jika pengguna sudah login, arahkan ke halaman yang sesuai berdasarkan role
  if (authStatus.isValid) {
    return authStatus.role === "admin" ? (
      <Navigate to="/admin" replace />
    ) : (
      <Navigate to="/user/dashboard" replace />
    );
  }

  // Jika belum login, izinkan akses ke halaman autentikasi
  return <Outlet />;
};

export default RestrictedRoute;
