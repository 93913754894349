import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../config/apiConfig";

export default function DetailEvacuationPointGrid() {
  const { id } = useParams();
  const [evacuationPoint, setEvacuationPoint] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchEvacuationPointDetails = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/evacuation-points/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch evacuation point details");
        }
        const data = await response.json();
        setEvacuationPoint(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvacuationPointDetails();
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        p: 4,
      }}
    >
      {/* Title */}
      <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
        Evacuation Point Details
      </Typography>

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // Side-by-side layout
          gap: 4,
        }}
      >
        {/* Evacuation Information Table */}
        <Box sx={{ flex: 1 }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Evacuation Information
          </Typography>

          {evacuationPoint && (
            <TableContainer component={Paper} sx={{ p: 2 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>ID</strong>
                    </TableCell>
                    <TableCell>{evacuationPoint.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Latitude</strong>
                    </TableCell>
                    <TableCell>{evacuationPoint.latitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Longitude</strong>
                    </TableCell>
                    <TableCell>{evacuationPoint.longitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Province</strong>
                    </TableCell>
                    <TableCell>
                      {evacuationPoint.location.province?.name || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>City</strong>
                    </TableCell>
                    <TableCell>
                      {evacuationPoint.location.city?.name || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>District</strong>
                    </TableCell>
                    <TableCell>
                      {evacuationPoint.location.district?.name || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Village</strong>
                    </TableCell>
                    <TableCell>
                      {evacuationPoint.location.village?.name || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Created At</strong>
                    </TableCell>
                    <TableCell>
                      {new Date(evacuationPoint.created_at).toLocaleString(
                        "id-ID"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>

        {/* Map Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {evacuationPoint && (
            <iframe
              title="Evacuation Point Location"
              width="100%"
              height="100%"
              style={{ border: 0, borderRadius: 8 }}
              src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyDwH-ZEUpjMoqynooNGEYtXyuWmxJNWLDY&center=${evacuationPoint.latitude},${evacuationPoint.longitude}&zoom=18`}
              allowFullScreen
            ></iframe>
          )}
        </Box>
      </Box>

      {/* Back Button */}
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/admin/evacuation-point")}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
}
