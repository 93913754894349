import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";
import Copyright from "../internals/components/Copyright";

export default function YourApiGrid() {
  const [apiKeys, setApiKeys] = useState([]);
  const token = localStorage.getItem("token");

  const fetchApiKeys = async () => {
    try {
      const response = await fetch(`${API_URL}/v1/api-keys/my-api-keys`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch API keys");

      const data = await response.json();
      console.log("API Keys:", data.data);

      setApiKeys([data.data] || []);
    } catch (error) {
      console.error("Error fetching API keys:", error);
    }
  };

  const handleGenerateOrUpdateApiKey = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: apiKeys.length
          ? "This will update your existing API key."
          : "This will generate a new API key.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: apiKeys.length
          ? "Yes, update it!"
          : "Yes, generate it!",
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: apiKeys.length
            ? "Updating API Key..."
            : "Generating API Key...",
          text: "Please wait while we process your request.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(`${API_URL}/v1/api-keys/generate`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (!data?.data?.apiKey) {
          throw new Error("Invalid response format");
        }

        await Swal.fire({
          title: apiKeys.length ? "Updated!" : "Generated!",
          text: `Your ${apiKeys.length ? "updated" : "new"} API key is: ${
            data.data.apiKey
          }`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        fetchApiKeys();
      }
    } catch (error) {
      console.error("Error generating or updating API key:", error);

      Swal.fire({
        title: "Error",
        text: error.message || "Failed to process API key. Please try again.",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleRevokeApiKey = async (key) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `This will revoke the API key: ${key}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, revoke it!",
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: "Revoking API Key...",
          text: "Please wait while we process your request.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(`${API_URL}/v1/api-keys/revoke`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": key,
          },
        });

        if (!response.ok) throw new Error("Failed to revoke API key");

        Swal.fire({
          title: "Revoked!",
          text: "The API key has been revoked successfully.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => window.location.reload());
      }
    } catch (error) {
      console.error("Error revoking API key:", error);

      Swal.fire({
        title: "Error",
        text: error.message || "Failed to revoke API key. Please try again.",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
    }
  };

  const handleValidateApiKey = async (key) => {
    try {
      const response = await fetch(`${API_URL}/v1/api-keys/validate`, {
        method: "GET",
        headers: {
          "x-api-key": `${key}`,
        },
      });

      if (!response.ok) throw new Error("Failed to validate API key");

      const data = await response.json();
      Swal.fire({
        title: "Validated!",
        text: data.message || "The API key is valid.",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      console.error("Error validating API key:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to validate API key.",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const columns = [
    { accessorKey: "api_key", header: "API Key" },
    { accessorKey: "created_at", header: "Created At" },
    { accessorKey: "reset_date", header: "Reset Date" },
    {
      id: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleValidateApiKey(row.original.api_key)}
          >
            Validate
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => handleRevokeApiKey(row.original.api_key)}
          >
            Revoke
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        YOUR API KEYS
      </Typography>

      {/* Generate API Key Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateOrUpdateApiKey}
        sx={{ mb: 2 }}
      >
        {apiKeys.length ? "Update API Key" : "Generate New API Key"}
      </Button>

      {/* API Keys Table */}
      <MaterialReactTable columns={columns} data={apiKeys} />

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
