import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState, useEffect } from "react";
import Copyright from "../internals/components/Copyright";
import { MaterialReactTable } from "material-react-table";
import { API_URL } from "../../config/apiConfig";

export default function UserClientGrid() {
  const [userData, setUserData] = useState([]);
  const token = localStorage.getItem("token");

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${API_URL}/v1/admin/getAllUsers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch user data");

      const data = await response.json();
      console.log("Fetched Users:", data.data.users);

      // Add a sequential number to each user
      const numberedUsers = data.data.users.map((user, index) => ({
        ...user,
        no: index + 1,
        created_at: new Date(user.created_at).toLocaleString("id-ID", {
          timeZone: "Asia/Jakarta",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
      }));
      setUserData(numberedUsers || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const columns = [
    { accessorKey: "no", header: "No" },
    { accessorKey: "email", header: "Email" },
    { accessorKey: "role", header: "Role" },
    { accessorKey: "created_at", header: "Created At" },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* Title */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        YOUR USER CLIENT
      </Typography>

      {/* User Table */}
      <MaterialReactTable columns={columns} data={userData} />

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
