import { API_URL } from "./apiConfig";

export const validateToken = async () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return { isValid: false, role: null, userId: null };
  }

  try {
    const response = await fetch(`${API_URL}/v1/auth/validate-token`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.warn("Token validation failed on server");
      return { isValid: false, role: null, userId: null };
    }

    const data = await response.json();

    // Ambil data `role` dan `userId` dari respons API
    const { role, userId } = data.data;

    return { isValid: true, role, userId };
  } catch (error) {
    console.error("Error validating token:", error);
    return { isValid: false, role: null, userId: null };
  }
};
