import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  Link,
  Card as MuiCard,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Altcha from "../../config/Altcha";
import { API_URL } from "../../config/apiConfig";
import AppTheme from "../../shared-theme/AppTheme";
import ColorModeSelect from "../../shared-theme/ColorModeSelect";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function SignUp(props) {
  const navigate = useNavigate();
  const altchaRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const validateInputs = () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    }

    if (!password || password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      isValid = false;
    }

    return isValid;
  };

  const calculatePasswordStrength = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*]/.test(password);
    const lengthCheck = password.length >= 8;

    if (
      lengthCheck &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecial
    ) {
      setPasswordStrength("Strong");
    } else if (lengthCheck && (hasUpperCase || hasLowerCase) && hasNumbers) {
      setPasswordStrength("Medium");
    } else {
      setPasswordStrength("Weak");
    }
  };

  const getAltchaToken = () => altchaRef.current?.value;

  const handleRegister = async () => {
    if (!validateInputs()) return;

    Swal.fire({
      title: "Processing...",
      text: "Sending OTP to your email.",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    const altchaToken = getAltchaToken();

    try {
      const role = "user";
      // Register user
      const registerResponse = await fetch(`${API_URL}/v1/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, altchaToken }),
      });

      if (!registerResponse.ok) {
        throw new Error(await registerResponse.text());
      }

      Swal.close();

      // Prompt user for OTP
      const { value: otp } = await Swal.fire({
        title: "Enter OTP",
        input: "text",
        inputLabel: "Check your email for the OTP",
        inputPlaceholder: "Enter OTP here",
        allowOutsideClick: false,
        inputValidator: (value) => {
          if (!value) return "OTP is required!";
          if (value.length !== 6) return "OTP must be 6 digits!";
        },
      });

      if (otp) {
        Swal.fire({
          title: "Verifying OTP...",
          allowOutsideClick: false,
          didOpen: () => Swal.showLoading(),
        });

        // Verify OTP
        const verifyResponse = await fetch(
          `${API_URL}/v1/auth/verify-registration-otp`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email,
              otp,
              password,
              role,
            }),
          }
        );

        if (!verifyResponse.ok) {
          throw new Error(await verifyResponse.text());
        }

        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Registration Successful!",
          text: "You can now log in to your account.",
        });

        navigate("/auth/sign-in");
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.message || "An error occurred.",
      });
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <ColorModeSelect sx={{ position: "fixed", top: "1rem", right: "1rem" }} />
      <SignUpContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: "100%",
              fontSize: "clamp(2rem, 10vw, 2.15rem)",
              textAlign: "center",
            }}
          >
            Ada Gempa Dashboard Authentication Register
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRegister();
              }
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="your@email.com"
                name="email"
                autoComplete="off"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
              <Typography variant="body2" mt={1}>
                {" "}
                Make sure your email is active for authentication purposes{" "}
              </Typography>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                id="password"
                placeholder="••••••"
                type="password"
                autoComplete="off"
                variant="outlined"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  calculatePasswordStrength(e.target.value);
                }}
                error={!!passwordError}
                helperText={passwordError}
              />
              <Typography
                variant="body2"
                sx={{
                  color:
                    passwordStrength === "Strong"
                      ? "green"
                      : passwordStrength === "Medium"
                      ? "orange"
                      : "red",
                }}
              >
                Strength: {passwordStrength}
              </Typography>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="confirm-password">Confirm Password</FormLabel>
              <TextField
                required
                fullWidth
                id="confirm-password"
                placeholder="••••••"
                type="password"
                autoComplete="off"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
              />
            </FormControl>
            <FormControl>
              <Altcha ref={altchaRef} />
            </FormControl>
            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={handleRegister}
            >
              Sign up
            </Button>
            <Typography sx={{ textAlign: "center" }}>
              Already have an account?{" "}
              <Link
                component="button"
                onClick={() => navigate("/")}
                variant="body2"
                sx={{ cursor: "pointer" }}
              >
                Sign in
              </Link>
            </Typography>
          </Box>
          <Typography sx={{ textAlign: "center" }}>
            Copyright © Chaidar Aria 2024 <br /> Ada Gempa Framework 1.0
          </Typography>
        </Card>
      </SignUpContainer>
    </AppTheme>
  );
}
