import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  Card as MuiCard,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Altcha from "../../config/Altcha";
import { API_URL } from "../../config/apiConfig";
import AppTheme from "../../shared-theme/AppTheme";
import ColorModeSelect from "../../shared-theme/ColorModeSelect";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function AdminSignUp(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const altchaRef = useRef(null); // Ref untuk Altcha

  const validateInputs = () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    }

    if (!password || password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      isValid = false;
    }

    return isValid;
  };

  const getAltchaToken = () => altchaRef.current?.value;

  const handleRegister = async () => {
    if (!validateInputs()) return;

    const altchaToken = getAltchaToken();

    if (!altchaToken) {
      Swal.fire({
        icon: "error",
        title: "Captcha Required",
        text: "Please complete the captcha challenge.",
      });
      return;
    }

    Swal.fire({
      title: "Processing...",
      text: "Registering admin account.",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const role = "admin";
      const registerResponse = await fetch(`${API_URL}/v1/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, role, altchaToken }),
      });

      if (!registerResponse.ok) {
        throw new Error(await registerResponse.text());
      }

      Swal.close();

      Swal.fire({
        icon: "success",
        title: "Admin Registered Successfully!",
        text: "You can now log in to your admin dashboard.",
        timer: 5000,
        showConfirmButton: false,
      }).then(() => navigate("/auth/sign-in"));
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.message || "An error occurred.",
      });
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <ColorModeSelect sx={{ position: "fixed", top: "1rem", right: "1rem" }} />
      <SignUpContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: "100%",
              fontSize: "clamp(2rem, 10vw, 2.15rem)",
              textAlign: "center",
            }}
          >
            Admin Registration
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRegister();
              }
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="admin@example.com"
                name="email"
                autoComplete="off"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                id="password"
                placeholder="••••••"
                type="password"
                autoComplete="off"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="confirm-password">Confirm Password</FormLabel>
              <TextField
                required
                fullWidth
                id="confirm-password"
                placeholder="••••••"
                type="password"
                autoComplete="off"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
              />
            </FormControl>
            <FormControl>
              <Altcha ref={altchaRef} />
            </FormControl>
            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={handleRegister}
            >
              Register Admin
            </Button>
          </Box>
          <Typography sx={{ textAlign: "center" }}>
            Copyright © Chaidar Aria 2024 <br /> Ada Gempa Framework 1.0
          </Typography>
        </Card>
      </SignUpContainer>
    </AppTheme>
  );
}
