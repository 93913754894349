import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { validateToken } from "./authUtils";

const ProtectedRoute = ({ allowedRoles }) => {
  const [authStatus, setAuthStatus] = useState({ isValid: null, role: null });

  useEffect(() => {
    const checkToken = async () => {
      const result = await validateToken();
      setAuthStatus(result);
    };

    checkToken();
  }, []);

  if (authStatus.isValid === null) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-blue-500"></div>
      </div>
    );
  }

  // Jika token tidak valid, arahkan ke halaman login
  if (!authStatus.isValid) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Jika role tidak diizinkan, arahkan ke halaman login
  if (allowedRoles && !allowedRoles.includes(authStatus.role)) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Jika token valid dan role sesuai, izinkan akses
  return <Outlet />;
};

export default ProtectedRoute;
