import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminSignUp from "./Authentication/AdminRegistration/AdminRegistration";
import SignIn from "./Authentication/SignIn/SignIn";
import SignUp from "./Authentication/SingUp/SignUp";
import { API_URL } from "./config/apiConfig";
import ProtectedRoute from "./config/ProtectedRoute";
import RestrictedRoute from "./config/RestrictedRoute";
import Dashboard from "./Dashboard/Dashboard";
import DetailEarthquake from "./Dashboard/DetailEarthquake";
import DetailEvacuationPoint from "./Dashboard/DetailEvacuationPoint";
import Earthquake from "./Dashboard/Earthquake";
import EvacuationPoint from "./Dashboard/EvacuationPoint";
import EvacuationSimulation from "./Dashboard/EvacuationSimulation";
import Location from "./Dashboard/Location";
import UserClient from "./Dashboard/UserClient";
import YourApiKey from "./Dashboard/YourApiKey";

function App() {
  const [isAdminRegistered, setIsAdminRegistered] = useState(null);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/auth/admin-check`);
        const data = await response.json(); // Parse respons JSON

        if (data.responseCode === 404) {
          // Admin belum terdaftar
          setIsAdminRegistered(false);
        } else {
          // Admin sudah terdaftar
          setIsAdminRegistered(true);
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setIsAdminRegistered(false); // Anggap admin belum terdaftar jika error
      }
    };

    checkAdmin();
  }, []);

  if (isAdminRegistered === null) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-blue-500"></div>
      </div>
    );
  }

  // Jika admin belum terdaftar, redirect semua akses ke halaman registrasi admin
  if (!isAdminRegistered) {
    return (
      <Routes>
        <Route path="*" element={<AdminSignUp />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* Route apapun diarahkan ke registrasi admin */}
      <Route path="*" element={<AdminSignUp />} />
      {/* Redirect rute utama ke /auth/sign-in */}
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />

      {/* Rute untuk autentikasi */}
      <Route element={<RestrictedRoute />}>
        <Route path="/auth">
          <Route index element={<Navigate to="sign-in" replace />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="admin-regis" element={<AdminSignUp />} />
        </Route>
      </Route>

      {/* Rute untuk admin */}
      <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
        <Route path="/admin">
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="your-api-key" element={<YourApiKey />} />
          <Route path="clients" element={<UserClient />} />
          <Route path="earthquake" element={<Earthquake />} />
          <Route path="earthquake/:id" element={<DetailEarthquake />} />
          <Route path="location" element={<Location />} />
          <Route path="evacuation-point" element={<EvacuationPoint />} />
          <Route
            path="evacuation-point/:id"
            element={<DetailEvacuationPoint />}
          />
          <Route
            path="evacuation-simulation"
            element={<EvacuationSimulation />}
          />
        </Route>
      </Route>

      {/* Rute untuk user */}
      <Route element={<ProtectedRoute allowedRoles={["user"]} />}>
        <Route path="/user">
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="your-api-key" element={<YourApiKey />} />
          <Route path="earthquake" element={<Earthquake />} />
          <Route
            path="evacuation-simulation"
            element={<EvacuationSimulation />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
