import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "altcha";
import { API_URL } from "./apiConfig";

const Altcha = forwardRef(({ onStateChange }, ref) => {
  const widgetRef = useRef(null); // Referensi ke widget Altcha
  const [value, setValue] = useState(null); // State untuk menyimpan payload Altcha

  useImperativeHandle(
    ref,
    () => ({
      // Menyediakan properti `value` untuk diakses dari luar
      get value() {
        return value;
      },
    }),
    [value]
  );

  useEffect(() => {
    const handleStateChange = (ev) => {
      if ("detail" in ev) {
        const newValue = ev.detail.payload || null; // Ambil payload dari event
        setValue(newValue); // Simpan payload di state
        onStateChange?.(ev); // Panggil callback jika disediakan
      }
    };

    const { current } = widgetRef; // Ambil referensi widget

    if (current) {
      current.addEventListener("statechange", handleStateChange); // Tambahkan event listener
      return () =>
        current.removeEventListener("statechange", handleStateChange); // Hapus listener saat komponen unmount
    } 
  }, [onStateChange]);

  return (
    <altcha-widget
      ref={widgetRef}
      style={{
        "--altcha-max-width": "100%",
      }}
      challengeurl={`${API_URL}/v1/auth/altcha/challenge`} // URL untuk mendapatkan tantangan
    ></altcha-widget>
  );
});

export default Altcha;
